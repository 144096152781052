/* 
* Author M. Atoar Rahman
* Date: 15/03/2022
* Time: 11:40 AM  
*/
.notFound {
  /* background-image: url(../assets/pattern.jpg); */
  /* background-color: rgb(68 33 175); */
  /* background-size: cover; */
  /* background-position: 0 0; */
  /* height: 100vh; */
  overflow: hidden;
  text-align: center;
  display: grid;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.logo {
  display: grid;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
}
.notFound form {
  display: grid;
  background: rgb(187 187 187 / 45%);
  padding: 30px;
  border-radius: 10px;
  min-width: 25rem;
  min-height: 17rem;
}
.notFound form h4 {
  margin: 0;
  font-size: 1.8em;
  font-weight: 400;
  color: #fff;
}
.notFound form span {
  margin-bottom: 15px;
  font-size: 0.8em;
}
.notFound a:hover {
  color: #fff;
}
.forgetPassword {
  text-align: end;
  color: #fff;
  margin-bottom: 20px;
}

/* Forget Password */
.buttonGroups {
  justify-content: center;
}
.btnGroup {
  border-radius: 20px;
  min-width: 8rem;
}
