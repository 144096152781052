/*
 * Author M. Atoar Rahman
 * Title: CSS
 * Description: CSS
 * Date: 15/03/2022
 */

/* General Style  */
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  overflow: hidden;
}

a {
  text-decoration: none !important;
}
ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}
.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.required {
  color: #ff0000;
  margin-left: 0.2rem;
}
.invalid {
  color: #ff0000;
  margin-left: 0.2rem;
}

.invalidInput {
  border-color: #ff0000;
  outline: 0;
}
.invalidInput__control {
  border-color: #ff0000 !important;
  outline: 0;
}
.css-1pahdxg-control {
  border-color: #86b7fe !important;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
}
.invalidInput__control--is-focused {
  box-shadow: 0px 0px 3px #ff0000d0 !important;
}

html,
body,
#root {
  min-height: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6,
.card-title,
.modal-title {
  color: #444444;
}
[role="button"] {
  cursor: pointer;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
.error {
  color: #f30008;
  background: #ff00001c;
  border-radius: 5px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 0;
}

.svg-icon svg {
  width: 20px;
  height: 20px;
}
.nav-sidebar .nav-treeview .nav-item > .nav-link > .svg-icon > svg {
  width: 12px;
  height: 12px;
  margin-left: 5px;
}
.svg-icon-light svg {
  fill: #ffffff;
}
.svg-icon-offwhite svg {
  fill: rgba(255, 255, 255, 0.75);
}
.svg-icon-dark svg {
  fill: #000000;
}
.sidebar .svg-icon {
  margin-right: 5px;
}

.float-right {
  float: right !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.padding_15 {
  padding: 15px;
}

.form-label {
  margin-bottom: 0rem;
  line-height: 18px;
}

.table > thead {
  color: #444444;
}
.table > thead > tr > th {
  font-weight: 600;
  line-height: 20px;
}
.table > :not(:first-child) {
  border-top: 2px solid #ddd;
}

.unhcrSelect{position: relative; z-index: 9999;}

/* Scrollbar width */
.content-wrapper .padding_15 {
  height: calc(100vh - (3.5rem + 1px));
  overflow-x: hidden;
  overflow-y: initial;
}
::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: #0164a5;
  border-radius: 10px;
}

/* Scrollbar Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0164a5;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #1168a1;
}
.sidebar::-webkit-scrollbar-thumb:hover {
  background: #0863a0;
}

/* Main Header  */
.main-header {
  /* background-image: url(../assets/pattern.jpg);
    background-color: rgb(68 33 175); */
  background: #0072bc;
  background-position: 0 0;
  background-repeat: repeat-x;
  border-color: #003c78;
  border-bottom: 1px solid #dee2e6;
  z-index: 1034;
}
.navbar {
  position: fixed;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
  top: 0;
  left: 0;
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.main-header .navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.main-header .navbar-nav .nav-item {
  margin: 0;
}
.main-header .navbar-nav .nav-link {
  height: 2.5rem;
  position: relative;
  padding-right: 15px !important;
  padding-left: 11px !important;
  color: #ffffff !important;
}
.main-header .navbar-nav .nav-link {
  font-size: 16px;
}

.main-header .navbar-nav .nav-link:hover,
.main-header .navbar-nav .nav-link:focus {
  color: white;
}
.nav-sidebar .nav-item > .nav-link {
  position: relative;
}
.nav-sidebar .nav-treeview {
  display: none;
  list-style: none;
  padding: 0;
}
.menu-open ul.nav-treeview {
  display: block;
}
.menu-open ul.nav-treeview ul.nav-treeview{
  padding-left: 10px;
}

.sidebar a {
  color: #ffffff;
}

.nav-sidebar .nav-link > .right,
.nav-sidebar .nav-link > p > .right {
  position: absolute;
  right: 1rem;
  top: 0.7rem;
}

.nav-sidebar .nav-item > .nav-link .right {
  transition: -webkit-transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
}

.nav-sidebar .menu-open > .nav-link svg.right,
.nav-sidebar .menu-open > .nav-link i.right,
.nav-sidebar .menu-open > .nav-link svg.right,
.nav-sidebar .menu-open > .nav-link i.right {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

[class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link:hover,
[class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link:focus {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.main-wrapper .main-sidebar .nav-link {
  width: calc(250px - 0.5rem * 2);
  transition: width ease-in-out 0.3s;
}
.nav-sidebar .nav-item > .nav-link {
  margin-bottom: 0.2rem;
}

.navbar-search-block {
  position: absolute;
  padding: 0 1rem;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: none;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: initial;
}
.navbar-search-open {
  display: flex;
}
.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}
.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.navbar-search-block .input-group {
  width: 100%;
}
.navbar-light .form-control-navbar:focus,
.navbar-light .form-control-navbar:focus + .input-group-append .btn-navbar {
  background-color: #d3d9df;
  border-color: #c7ced5 !important;
  color: #ced4da;
}
.navbar-light .navbar-search-block .form-control-navbar:focus,
.navbar-light
  .navbar-search-block
  .form-control-navbar:focus
  + .input-group-append
  .btn-navbar {
  color: rgba(0, 0, 0, 0.6);
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.8125rem + 2px);
}
.main-header .btn-navbar {
  background-color: #3f474e;
  border: 1px solid #6c757d;
  color: white;
}
.main-header .form-control-navbar {
  background-color: #e7e7e7;
  border: 1px solid #e7e7e7;
  color: white;
}
.main-header .form-control-navbar {
  background-color: #e7e7e7;
  border-color: #e7e7e7;
}

.navbar-search-block .btn-navbar {
  background: #e7e7e7;
  border-color: #bfbfbf;
  color: #212529;
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
.badge-warning {
  color: #1f2d3d;
  background-color: #ffc107;
}
.navbar-badge {
  position: absolute;
  right: 3px;
  top: 5px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 5px;
  font-size: 0.6rem !important;
  font-weight: 300 !important;
  padding: 2px 4px !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Sidebar  */
.main-sidebar {
  height: 100vh;
  overflow-y: hidden;
  z-index: 1038;
  /* background-image: url(../assets/pattern.jpg);
    background-color: rgb(68 33 175); */
  background: #0072bc;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%) !important;
}

.main-wrapper .main-sidebar {
  bottom: 0;
  float: none;
  left: 0;
  position: fixed;
  top: 0;
}

.main-sidebar,
.main-sidebar::before {
  transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
  width: 250px;
}
aside,
nav {
  display: block;
}
@media (min-width: 992px) {
  .main-wrapper.sidebar-collapse .main-sidebar,
  .main-wrapper.sidebar-collapse .main-sidebar::before {
    margin-left: 0;
    width: 4.6rem;
  }
  .main-wrapper.sidebar-collapse .main-sidebar {
    overflow-x: hidden;
  }
}

/* Brand Link  */
.brand-link {
  display: block;
  font-size: 1.25rem;
  line-height: 0;
  padding: 1rem 0.5rem 0.7rem 0.5rem;
  transition: width 0.3s ease-in-out;
  white-space: nowrap;
}

[class*="sidebar-dark"] .brand-link,
[class*="sidebar-dark"] .brand-link .pushmenu {
  color: rgba(255, 255, 255, 0.8);
}
[class*="sidebar-dark"] .brand-link {
  box-shadow: 0 1px 48px rgb(0 0 0 / 20%);
}
.main-wrapper .brand-link {
  width: 250px;
}

.brand-link .brand-image {
  float: left;
  line-height: 0.8;
  margin-left: 0.8rem;
  margin-right: 0.5rem;
  margin-top: -5px;
  max-height: 35px;
  width: auto;
}

.sidebar .nav-link p,
.main-sidebar .brand-text {
  transition: margin-left 0.3s linear, opacity 0.3s ease, visibility 0.3s ease;
}

.font-weight-light {
  font-weight: 300 !important;
}

.sidebar {
  overflow-x: hidden;
  overflow-y: initial;
  padding-bottom: 45px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0;
  scrollbar-color: #a9a9a9 transparent;
  scrollbar-width: none;
}

.main-wrapper .sidebar {
  height: calc(100vh - (5rem + 1px));
}

@media (min-width: 992px) {
  .main-wrapper.sidebar-collapse .sidebar .nav-sidebar .nav-link p,
  .main-wrapper.sidebar-collapse .brand-text {
    margin-left: -10px;
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden;
  }
}
@media (min-width: 992px) {
  .main-wrapper.sidebar-collapse
    .main-sidebar:not(.sidebar-no-expand):hover
    .sidebar
    .nav-sidebar
    .nav-link
    p,
  .main-wrapper.sidebar-collapse
    .main-sidebar:not(.sidebar-no-expand):hover
    .brand-text,
  .main-wrapper.sidebar-collapse
    .main-sidebar:not(.sidebar-no-expand).sidebar-focused
    .sidebar
    .nav-sidebar
    .nav-link
    p,
  .main-wrapper.sidebar-collapse
    .main-sidebar:not(.sidebar-no-expand).sidebar-focused
    .brand-text {
    display: inline-block;
    margin-left: 0;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .main-wrapper.sidebar-collapse .main-sidebar:not(.sidebar-no-expand):hover,
  .main-wrapper.sidebar-collapse
    .main-sidebar:not(.sidebar-no-expand).sidebar-focused {
    width: 250px;
  }
}
@media (min-width: 992px) {
  .main-wrapper.sidebar-collapse .main-sidebar,
  .main-wrapper.sidebar-collapse .main-sidebar::before {
    padding-left: 0;
    width: 4.6rem;
  }
}

@media (min-width: 992px) {
  .main-wrapper.sidebar-collapse .content-wrapper,
  .main-wrapper.sidebar-collapse .main-header {
    padding-left: 4.6rem !important;
  }
}
@media (min-width: 768px) {
  .content-wrapper,
  .main-header {
    transition: padding-left 0.3s ease-in-out;
    padding-left: 250px;
  }
}
.nav-pills .nav-link:not(.active):hover {
  color: #007bff;
}

[class*="sidebar-dark-"] .sidebar a:hover,
[class*="sidebar-dark-"] .sidebar a:focus {
  text-decoration: none;
}
[class*="sidebar-dark-"] .nav-sidebar > .nav-item > .nav-link:active {
  color: #c2c7d0;
}
[class*="sidebar-dark-"] .nav-sidebar > .nav-item.menu-open > .nav-link,
[class*="sidebar-dark-"] .nav-sidebar > .nav-item:hover > .nav-link,
[class*="sidebar-dark-"] .nav-sidebar > .nav-item > .nav-link:focus {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.nav-sidebar > .nav-item .nav-icon {
  margin-left: 0.05rem;
  font-size: 1.2rem;
  margin-right: 0.2rem;
  text-align: center;
  width: 1.6rem;
}

.nav-sidebar .nav-item .nav-link svg {
  transition: color 1s ease, background-color 1s ease;
}
.nav-sidebar .nav-item .nav-link:hover svg {
  transition: color 1s ease, background-color 1s ease;
  fill: #009ef7 !important;
}

.nav-sidebar .nav-link p {
  display: inline;
  margin: 0;
  white-space: normal;
}

[class*="sidebar-dark"] .brand-link:hover,
[class*="sidebar-dark"] .brand-link .pushmenu:hover {
  color: #fff;
}

.brand-text {
  font-size: 30px;
  margin-bottom: 0;
  line-height: 10px;
  font-weight: 900;
  letter-spacing: 0.1em;
  color: #fff;
}
.brand-text span {
  font-size: 10px;
  letter-spacing: 0px;
}
.nav-pills .nav-link.active {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
}

/* Content Wrapper  */
.content-wrapper {
  height: 100%;
  background-color: #f4f6f9;
  margin-top: 3.5rem;
  min-height: calc(100vh - 3.5rem);
}

.control-sidebar {
  bottom: calc(3.5rem + 1px);
  position: absolute;
  top: calc(3.5rem + 1px);
  z-index: 1031;
}

.main-wrapper .control-sidebar {
  bottom: 0;
  float: none;
  position: fixed;
  top: 0;
}

.control-sidebar-dark,
.control-sidebar-dark a,
.control-sidebar-dark .nav-link {
  color: #c2c7d0;
}

.control-sidebar-dark {
  background-color: #343a40;
}

.control-sidebar,
.control-sidebar::before {
  bottom: calc(3.5rem + 1px);
  display: none;
  right: -250px;
  width: 250px;
  transition: right 0.3s ease-in-out, display 0.3s ease-in-out;
}

#sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1037;
}

.asideFooter {
  width: 100%;
  position: absolute;
  padding: 10px 0px;
  bottom: 0;
  text-align: center;
  color: #d3d9df;
  background: #0072bc;
}
.asideFooter h4 {
  margin-bottom: 0;
  font-size: 12px;
  color: #ffffff;
}
.asideFooter p {
  margin-bottom: 0;
  font-size: 10px;
}

.sidebar-closed .main-sidebar,
.sidebar-collapse .main-sidebar::before {
  margin-left: -250px;
}
.sidebar-closed .main-header,
.sidebar-closed .content-wrapper {
  margin-left: 0px !important;
}

/* Module Base Global Style  */
/* Notification */
.nav-item.notification .nav-link {
  position: relative;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.nav-item.notification .pulse-ring {
  position: absolute;
  height: 40px;
  width: 40px;
  right: 2px;
  top: 0px;
  display: block;
  border-radius: 50%;
  animation: animation-pulse 3.5s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  border-width: 4px;
  border-style: solid;
  border-color: #ffffff;
}
@keyframes animation-pulse {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

/* Nav User */
.navUserInfo button.nav-link {
  padding-right: 0px;
  padding-left: 0px;
  border: 0;
  background: none;
}
.navUserInfo ul.dropdown-menu {
  top: 47px;
  right: 7px;
  padding: 0px;
  min-width: 18rem;
}
.navUserInfo ul.dropdown-menu .userContent {
  padding: 15px 10px;
  border-bottom: 1px solid #eff2f5;
}
.navUserInfo ul.dropdown-menu .userContent img {
  width: 6rem;
  height: 6rem;
}
.navUserInfo ul.dropdown-menu .userContent p {
  margin-bottom: 0;
}
.navUserInfo ul.dropdown-menu .userFooter {
  padding: 10px;
}
.navUserInfo ul.dropdown-menu .userFooter button {
  min-width: 48%;
}

.sidebar nav {
  overflow: hidden;
}

.sidebar .nav-link {
  color: #ffffff;
}
.borderTop0 {
  border-top: 0;
}

.navUserInfo img {
  border-radius: 50%;
  height: 28px;
  margin-top: -8px;
  border: 3px solid rgb(255 255 255 / 22%);
}

.formGroup input {
  background: #ffffff66;
  border-color: #9d8de8;
}

[type="checkbox"] {
  cursor: pointer;
}
/* Card */

.card {
  box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05) !important;
  border: 0;
}
.cardHeader {
  background: none;
  border-color: #eff2f5;
}
.cardHeader h3 {
  display: block;
  float: left;
  font-size: 1.275rem;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 36px;
}
.cardHeader > div {
  display: block;
  float: right;
}

/* Modal */
@media (min-width: 992px) {
  .modal-dialog {
    max-width: 40rem;
  }
  .modalWidth55 .modal-dialog {
    max-width: 55rem;
  }
  .modalWidth80 .modal-dialog {
    max-width: 95%;
  }
}

.modal-header,
.modal-body {
  padding-left: 30px;
  padding-right: 30px;
}
.modal-title {
  font-size: 1em;
  color: #444444;
}

/* Button  */
.btnCancel {
  background: #ebeff3;
  border-color: #e2e6e9;
  color: #444444;
  min-width: 6rem;
}
.btnCancel:hover,
.btnCancel:focus {
  background: #dadee2;
  border-color: #dadee2;
  color: #444444;
}
.btnSteelBlue {
  background: #859db5;
  border-color: #859db5;
  color: #ffffff;
}
.btnSteelBlue:hover,
.btnSteelBlue:focus {
  background: #7299c0;
  border-color: #7299c0;
  color: #ffffff;
}
.btnSuccess {
  background: #1dc894;
  border-color: #1dc894;
  color: #ffffff;
}
.btnSuccess:hover,
.btnSuccess:focus {
  background: #01a976;
  border-color: #01a976;
  color: #ffffff;
}
.btnInfo {
  background: #3699ff;
  border-color: #3699ff;
  color: #ffffff;
}
.btnInfo:hover,
.btnInfo:focus {
  background: #1f8dfc;
  border-color: #1f8dfc;
  color: #ffffff;
}
.actionBtn {
  font-size: 11px;
  margin: 0 3px;
  color: #444444;
  transition: 1s;
}

.actionBtn svg {
  fill: #444;
  width: 11px;
}

.btnBgNonePrimary {
  display: block;
  float: right;
  background: none;
  color: #007bff;
  border: 0;
  text-decoration: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.btnBgNonePrimary:hover {
  background: none;
  color: #0769d3;
  border: 0;
}
.btnBgNonePrimary:focus {
  box-shadow: none;
  background: none;
}
.downloadCsvBtn {
  font-size: 14px;
  color: #0769d3;
  transition: 1s;
  border-color: #8cc2e6;
  position: relative;
}
.downloadCsvBtn:hover {
  border-color: #dddddd;
}
.downloadCsvBtn::after {
  position: absolute;
  display: block;
  white-space: nowrap;
  width: 60px;
  top: 35px;
  left: -10px;
  animation: fadeIn 0.4s;
  text-align: center;
  background: #f5f5f5;
  line-height: 24px;
  border-radius: 5px;
  font-size: 13px;
  color: #157efb;
  z-index: 1;
}
.downloadCsvBtn:hover::after {
  content: 'Export';
}
.searchClearBtn, .searchClearBtn:focus, .searchClearBtn:hover{
  background: none;
  border: 0;
  box-shadow: none;
  color: #0072bc;
  padding: 0px 10px;
  right: 0;
  position: absolute;
  top: 25%;
}


/* Badge */
.badgeActive {
  background-color: #daf7e7 !important;
  color: #01a96b;
  padding-bottom: 5px;
}
.badgeInactive {
  background-color: #fde0e0 !important;
  color: #f30008;
  padding-bottom: 5px;
}

/* Data Table  */
.hCBnvI,
.fhCFWO:not(:last-of-type) {
  border-color: #eff2f5 !important;
  border-bottom-color: #eff2f5 !important;
}
.rdt_TableBody {
  border-bottom: 1px solid #eff2f5;
}
ul.pagination {
  float: right;
  margin-top: 30px;
  margin-bottom: 15px;
}
ul.pagination li .page-link {
  border: 0;
  border-radius: 3px;
  padding: 0px 7px;
  margin-right: 0.475rem;
  color: #444444;
  min-width: 1.4rem;
  height: 1.4rem;
}
.page-item.active .page-link {
  background: #3699ff;
  border-color: #3699ff;
}
.rdt_Table .actionBtn:hover svg {
  fill: #1f8dfc;
}
.rdt_Table .actionBtn:hover i {
  color: #1f8dfc;
}

.rdt_TableCol > div > div{ white-space: normal !important; }
.rdt_TableHeadRow{background: #f7f7f7 !important; border-top: 1px solid #ddd;}

.data-table-extensions > .data-table-extensions-action{z-index: 1;}

.subTable .rdt_TableHeadRow{
  display: none;
}

.searchBox{
  position: relative;
}
.searchBox .search-icon{
  position: absolute;
  left: 5px;
  top: 23%;
  color: #0072bc;
}
.searchBox input{
  padding-left: 25px;
  border: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
}
.searchBox input:focus{
  box-shadow: none;
}


/* Table Row and Column Freeze */
.rdt_TableHeadRow > .rdt_TableCol:first-child,
.rdt_TableRow > .rdt_TableCell:first-child{
  position: sticky !important;
  z-index: 1;
  left: 0px;
  /* flex: 100 0 auto !important; */
  background-color: #fff;
  border-left: 1px solid #ddd;
}
.rdt_TableHeadRow > .rdt_TableCol:first-child{
  border-left: 1px solid #ddd;
  background-color: #f7f7f7;
}
.rdt_TableHead{
  z-index: 2 !important;
}
.rdt_TableRow:hover{
  border-bottom-color: #ddd !important;
  outline-color: #ddd !important;
}
.rdt_TableRow:hover .rdt_TableCell:first-child{
  background-color: #eeeeee;
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-property: background-color;
  transition-property: background-color;
}

/* D4 Report */
.d4report.modal-body .rdt_TableHeadRow > .rdt_TableCol:nth-child(2){
  border-left: 1px solid #ddd;
  text-align: center;
}
.d4report.modal-body .rdt_TableRow > .rdt_TableCell:nth-child(2){
  border-left: 1px solid #ddd;
  text-align: center;
  display: grid;
}


/* Bootstarp Table*/
.borderDashed.table > :not(caption) > * > * {
  border-style: dashed;
  border-color: #e7e7e7;
}

/* Fieldset  */
.customFieldset {
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 1px;
  border-style: solid;
  border-color: #ced4da;
  border-image: initial;
}

.customLegend {
  display: block;
  float: none;
  width: auto;
  padding-inline-start: 15px;
  padding-inline-end: 15px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  color: #444444;
}


.customFieldset{position: relative; z-index: 3;}
.customFieldset.donation .table-borderless > tbody > tr > td,
.customFieldset.donation .table-borderless > tbody > tr > th,
.customFieldset.donation .table-borderless > tfoot > tr > td,
.customFieldset.donation .table-borderless > tfoot > tr > th,
.customFieldset.donation .table-borderless > thead > tr > td,
.customFieldset.donation .table-borderless > thead > tr > th {
  border-bottom-width: 0;
  line-height: 10px !important;
}

.customFieldset2 {
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 1px;
  border-style: solid;
  border-color: #ced4da;
  border-image: initial;
}

.customFieldset2.donation .table-borderless > tbody > tr > td,
.customFieldset2.donation .table-borderless > tbody > tr > th,
.customFieldset2.donation .table-borderless > tfoot > tr > td,
.customFieldset2.donation .table-borderless > tfoot > tr > th,
.customFieldset2.donation .table-borderless > thead > tr > td,
.customFieldset2.donation .table-borderless > thead > tr > th {
  border-bottom-width: 0;
  line-height: 10px !important;
}

/* Form  */
.form-switch input {
  width: 30px;
  height: 20px;
}
.formFooter {
  display: block;
  float: right;
  margin-top: 30px;
}

.labelRightButton {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-bottom: 7px;
}
.persist {
  line-height: 18px;
}
.persist .form-check-input {
  width: 2em !important;
  height: 0.8rem !important;
}

.dark-light-bg {
  background: #f3f3f3;
  color: #444;
}
.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #f3f3f3;
  color: #444;
  padding: 0.3rem;
  position: relative;
  bottom: -10px;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}


.requisition_table .gelpCx{
  overflow-x: hidden;
}
@media (max-width: 920px) { 
  .requisition_table .gelpCx{
    overflow-x: auto !important;
  }
}

.react-datepicker-popper{
  z-index: 5;
}


.dataTableHeaderNone header{
  display: none;
}

.g-recaptcha {
  transform:scale(1.13);transform-origin:0;-webkit-transform:scale(1.13);
  transform:scale(1.13);-webkit-transform-origin:0 0;transform-origin:0 0;
}
  
/* Dashboard */
#tab-dashboard ul{border-bottom:0;}
#tab-dashboard ul li{
  width: 19.5%; 
  float: left; 
  margin-right: 5px;
  margin-bottom: 15px;
}
#tab-dashboard ul li .nav-link{
  border: 0;
  text-align: left;
  color:#fff;
  width: 100%;
  min-height: 100px;
  border-radius: 5px;
  transition: 0.5s;
  display: flex;
  align-items: center;
  padding: 10px;
}
#summary-tab{
  background: #17a2b8;
}
#summary-tab:hover{
  background: #128192;
}
#nearExpiryDrug-tab{
  background: #28a745;
}
#nearExpiryDrug-tab:hover{
  background: #20903a;
}
#itemsWithLowStock-tab{
  background: #5f00b5;
}
#itemsWithLowStock-tab:hover{
  background: #4c0092;
}
#stockReceived-tab{
  background: #be00d5;
}
#stockReceived-tab:hover{
  background: #a202b4;
}
#itemsPercentage-tab{
  background: #dc3545;
}
#itemsPercentage-tab:hover{
  background: #b42a38;
}

.padding-0{
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
}
.padding-0 .rdt_TableCol{
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
}